<template>
  <div class="table-wrapper" :style="`min-width: ${mobileTableWidth}; `">
    <a-table
      :columns="columns"
      :data-source="data.list || data.data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    columns() {
      return this.config.columns;
    },
    mobileTableWidth() {
      return this.$mq === 'mobile'
        ? (this.config.mobileTableWidth
          ? this.config.mobileTableWidth
          : '540px')
        : 'auto';
    },
  },
};
</script>
